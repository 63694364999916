'use strict'

import * as filestack from 'filestack-js'

const appConfig           = require('./../config.es6').getConfig()

let groupId, taskId, policy

export const init = (gid, tid, selector)=> {
  if ((gid === undefined) || (tid  === undefined) || (selector === undefined)) {
    $(`${selector} .filepicker-btn`).removeClass('filepicker-btn').addClass('filepicker-btn-disabled')
    return
  }

  groupId = gid
  taskId  = tid

  const fileUploadsFields     = $(`${selector} .fileuploads-field`)

  if (fileUploadsFields !== 0 && fileUploadsFields.length > 0) {
    for (let f = 0; f < fileUploadsFields.length; f++) {
      const field     = fileUploadsFields[f]
      const id        = field.getAttribute('id')
      const val       = field.getAttribute('value')
      const def       = $(field).data('definition')
      const btn       = $(`#btn-${id}`)
      let maxFiles    = 5
      let maxFileSizeInMb = 20

      if (def != null) {
        if (def.maxFiles !== undefined) maxFiles = def.maxFiles > 10 ? 10 : def.maxFiles
        if (def.maxFileSizeInMb !== undefined) maxFileSizeInMb = def.maxFileSizeInMb > 100 ? 100 : parseInt(def.maxFileSizeInMb)
      }

      if (val !== '') display(id, JSON.parse(val))

      if (btn !== null) {          
        $.ajax({
          url:        `/hub/files/${groupId}/policy`,
          method:     'POST',
          data:       { maxSize: maxFileSizeInMb*1024*1024 }
        }).then((generatedPolicy)=> {
          const options = {
            security: { 
              policy: generatedPolicy.policy,
              signature: generatedPolicy.signature
            }
          }
          const filepicker = filestack.init(appConfig.filestackApiKey, options)

          $(btn).on('click', (e)=> {
            btn.addClass('loading')

            const fieldId = e.currentTarget.getAttribute('id').slice(4)

            filepicker.picker({
              fromSources:        ['local_file_system', 'googledrive', 'gmail', 'dropbox'],
              maxFiles:           maxFiles,
              maxSize:            maxFileSizeInMb*1024*1024,
              disableTransformer: true,
              storeTo: {
                location:         's3',
                region:           'us-east-1',
                path:             `${groupId}/`,
                container:        'upload-storage',
                access:           'public'
              },
              onFileSelected: (file) => {
                if (file.size > (maxFileSizeInMb*1024*1024)) throw new Error(`Please select a file smaller than ${maxFileSizeInMb}MB`)
              },
              onUploadDone: (result) => {
                const newFiles = updateFieldValue(fieldId, result.filesUploaded, [])
                display(fieldId, newFiles)
                initEvents()
              },
              onClose: () => {
                btn.removeClass('loading')
              }
            }).open()
          })
        })
      }
    }

    initEvents()
  }
}

const updateFieldValue = (fieldId, filesUploaded, filesDeleted)=> {
  const field   = document.getElementById(fieldId)

  let currentFiles = field.getAttribute('value')
  if (currentFiles !== '') {
    currentFiles = JSON.parse(currentFiles)
  } else {
    currentFiles = []
  }
  const newFiles = currentFiles.concat(filesUploaded).filter(f => filesDeleted.indexOf(f.key) === -1)
  field.setAttribute('value', JSON.stringify(newFiles))
  return newFiles
}

const display = (fieldId, filesUploaded)=> {
  const filesEl       = document.getElementById(fieldId).parentElement.getElementsByClassName('fileuploads-list')[0]
  const btnTextEl     = document.getElementById(fieldId).parentElement.getElementsByClassName('filepicker-btn-text')[0]

  if(filesUploaded !== null) {
    filesEl.setAttribute('class', 'fileuploads-list')
    while (filesEl.firstChild) filesEl.removeChild(filesEl.firstChild)

    for (let b = filesUploaded.length - 1; b >= 0; b--) {
      const url = `https://${filesUploaded[b].container}.s3.amazonaws.com/${filesUploaded[b].key}`.replace(/\+/g, '%2B').replace(/%20/g, '+')

      const container = document.createElement('div')
      container.setAttribute('class', 'fileuploads-item clearfix')
      filesEl.appendChild(container)

      const icon = document.createElement('a')
      icon.setAttribute('href', url)
      icon.setAttribute('target', '_blank')
      container.appendChild(icon)

      icon.appendChild(displayIconImage(filesUploaded[b]))

      const title = document.createElement('a')
      title.setAttribute('href', url)
      title.setAttribute('target', '_blank')
      title.innerHTML = filesUploaded[b].filename
      container.appendChild(title)

      const br = document.createElement('br')
      container.appendChild(br)

      const subtitle = document.createElement('span')
      subtitle.setAttribute('class', 'small text-muted')
      subtitle.innerHTML = formatBytes(filesUploaded[b].size, 0) + ' | ' + formatTrashLink(filesUploaded[b].key)
      container.appendChild(subtitle)

      if (btnTextEl !== undefined) btnTextEl.innerHTML = '&nbsp;&nbsp;Choose more files to upload'
    }
  }
}

const displayIconImage = (fileData)=> {
  const imageMimeTypes = ['image/bmp','image/x-windows-bmp','image/gif','image/jpeg','image/pjpeg','image/jpg','image/png','image/x-png','image/tiff']
  const pdfMimeTypes = ['application/pdf','application/x-download']
  const excelMimeTypes = ['application/excel','application/vnd.ms-excel','application/msexcel','application/vnd.ms-office','application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']
  const powerpointMimeTypes = ['application/powerpoint','application/vnd.ms-powerpoint']
  const wordMimeTypes = ['application/msword','application/vnd.openxmlformats-officedocument.wordprocessingml.document','application/msword']

  if (imageMimeTypes.indexOf(fileData.mimetype) !== -1) {
    const img = document.createElement('img')
    img.setAttribute('src', `https://${fileData.container}.s3.amazonaws.com/${fileData.key}`.replace(/\+/g, '%2B').replace(/%20/g, '+'))
    img.setAttribute('width', '64')
    img.setAttribute('class', 'pull-left')
    return img
  } else if (pdfMimeTypes.indexOf(fileData.mimetype) !== -1) {
    const i = document.createElement('i')
    i.setAttribute('class', 'big file pdf outline icon pull-left')
    return i
  } else if (wordMimeTypes.indexOf(fileData.mimetype) !== -1) {
    const i = document.createElement('i')
    i.setAttribute('class', 'big file word outline icon pull-left')
    return i
  } else if (excelMimeTypes.indexOf(fileData.mimetype) !== -1) {
    const i = document.createElement('i')
    i.setAttribute('class', 'big file excel outline icon pull-left')
    return i
  } else if (powerpointMimeTypes.indexOf(fileData.mimetype) !== -1) {
    const i = document.createElement('i')
    i.setAttribute('class', 'big file powerpoint outline icon pull-left')
    return i
  } else {
    const i = document.createElement('i')
    i.setAttribute('class', 'big file outline icon pull-left')
    return i
  }
}

const formatBytes = (bytes, decimals)=> {
  if(bytes == 0) return '0 byte'
  const k = 1024
  const dm = decimals + 1 || 3
  const sizes = ['bytes', 'kb', 'mb', 'gb', 'tb']
  const i = Math.floor(Math.log(bytes) / Math.log(k))
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) +' '+ sizes[i]
}

const formatTrashLink = (key)=> {
  if (key === undefined) return ''
  else return `<a href='#' class='fileuploads-delete' id='delete-${key}'>Delete</a>`
}

export const formatter = (data)=> {
  let str = ''
  if (data !== null) {
    for (let b = data.length - 1; b >= 0; b--) {
      str += `<a href='https://${data[b].container}.s3.amazonaws.com/${data[b].key.replace(/\+/g, '%2B').replace(/%20/g, '+')}' target='_blank'>${data[b].filename}</a><br/>`
    }
  }
  return str
}

const initEvents = ()=> {
  $('.fileuploads-delete').off('click')
  $('.fileuploads-delete').on('click', (e)=> {
  if (confirm('Are you sure you want to permanently delete this file?')) { 
    e.preventDefault()
    const field = $(e.target).closest('.fileuploads-item')
    const fieldId = $(e.target).closest('.kotive-field-input').find('.fileuploads-field').attr('id')
    const key = $(e.target).attr('id').slice(7)
    $(e.target).replaceWith(`<span class="optional">Deleting file...</span>`)

    $.ajax({
      url: `/hub/files/${groupId}/${taskId}/delete?id=${key}`,
      type: 'DELETE',
      dataType: 'json',
      beforeSend:     (xhr)=> {
        xhr.setRequestHeader('Authorization', 'Basic ' + btoa(localStorage.getItem('user-email')+':'+localStorage.getItem('auth-token')))
      },
      success: (data, textStatus, jqXHR)=> {
        const refreshedFiles = updateFieldValue(fieldId, [], [key])
        field.replaceWith('<div class="fileuploads-item clearfix"><span class="optional">This file has been permanently deleted. <strong>You still need to save the form to keep record of this change</strong>.</span></div>')
      }
    })
  }
  return false;
})
}
