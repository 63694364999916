'use strict'

const observeEmailFields = ()=> {
  $('body').on('input', '[type="email"]', (e)=> {
    const email = $(e.target).val()
    if ((email === '') || (email === undefined)) {
      $('.email-indicator').removeClass('checkmark green remove orange')
      $(e.target).parent().parent().find('.invalid-field-message, .error-feedback, .success-feedback').hide()
    } else {
      if (isValidEmail(email)) {
        $('.email-indicator').addClass('checkmark green').removeClass('remove orange')
        $(e.target).parent().removeClass('error')
        $(e.target).parent().parent().find('.invalid-field-message').hide()
      } else {
        $('.email-indicator').removeClass('checkmark green').addClass('remove orange')
        $(e.target).parent().addClass('error')
        $(e.target).parent().parent().find('.invalid-field-message').show()
      }
    }
  })
}

const isValidEmail = (email)=> {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g
  return re.test(email)
}

const observeTelFields = ()=> {
  $('body').on('input', '[type="tel"]', (e)=> {
    const tel = $(e.target).val()
    if ((tel === '') || (tel === undefined)) {
      $('.tel-indicator').removeClass('checkmark green remove orange')
      $(e.target).parent().parent().find('.invalid-field-message, .error-feedback, .success-feedback').hide()
    } else {
      if (isValidTel(tel)) {
        $('.tel-indicator').addClass('checkmark green').removeClass('remove orange')
        $(e.target).parent().removeClass('error')
        $(e.target).parent().parent().find('.invalid-field-message').hide()
      } else {
        $('.tel-indicator').removeClass('checkmark green').addClass('remove orange')
        $(e.target).parent().addClass('error')
        $(e.target).parent().parent().find('.invalid-field-message').show()
        if (tel.charAt(0) !== '+') $(e.target).val(`+${tel}`)
      }
    }
  })
}

const isValidTel = (tel)=> {
  const re = /^\+[1-9]\d{10,14}$/g
  return re.test(tel)
}

export { observeEmailFields, isValidEmail, observeTelFields, isValidTel }