'use strict'

export const getConfig = ()=> {
  return {
    amplitudeAppKey:                'f9b576000c9779641fe889589d061069',
    filestackApiKey:                'Ajzc440l6TqiSLe7ieONYz',
    checkForAppUpdatesDelay:        300*1000,

    charEncodings: {
      "[":  "&sqrleft;",
      "]":  "&sqrright;",
      "{":  "&curlleft;",
      "}":  "&curlright;",
      ":":  "&colon;",
      "^":  "&kappie;"
    }
  }
}