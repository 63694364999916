'use strict'

export const revertUI = (element)=> {
  $(`${element} .header`).html('&nbsp;').show()
  $(`${element} .content`).html(`<br/><img src="${require('../../images/loading.gif')}" class="ui centered image"/>`)
  $(`${element} .actions .button`).show()
}

export const resizeHeight = (element)=> {
  const contentHeight = $(`${element} > .content`).height()
  const maxHeight     = $(window).height() - 230
  if (contentHeight < maxHeight) $(`${element} > .content`).height(maxHeight)
}