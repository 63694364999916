'use strict'

export const startup = (selector)=> {
  const conditionLookups = $(`${selector} .kotive-field-input[data-conditions]`)
  if (conditionLookups.length > 0) {
    evaluateConditions(conditionLookups.data('conditions').conditions)
    setupEvents(selector, conditionLookups.data('conditions').conditions)
  }
}

const evaluateConditions = (conditionLookups)=> {
  conditionLookups.forEach((condition)=> {
    evaluateCondition(condition.fieldId, conditionLookups)
  })
}

const findCondition = (fieldId, conditionLookups)=> {
  let condition = undefined
  conditionLookups.forEach((c)=> {
    if (fieldId == c.fieldId) condition = c
  })
  return condition
}

const evaluateCondition = (fieldId, conditionLookups)=> {
  const condition = findCondition(fieldId, conditionLookups)
  if (condition != null) {
    let field = $(`[name^='kotive-field-'][name*='_${fieldId}']`).closest('.kotive-field-input')
    if (field.length === 0) field = $(`label[for^='kotive-field-'][for*='_${fieldId}']`).closest('.kotive-field-input')
    if (field.length === 0) field = $(`[id*='_${fieldId}']`).closest('.kotive-field-input') // find paragraphs
    if (field.length > 0) {
      if (evaluateORs(condition.fieldId, condition.or, conditionLookups)) {
        field.removeClass('hidden')
      } else {
        field.addClass('hidden')
      }
    }
  }
}

const evaluateORs = (fieldId, ors, conditionLookups)=> {
  let result = false
  ors.forEach((or)=> {
    if (!result) result = evaluateANDs(fieldId, or.and, conditionLookups)
  })
  return result
}

const evaluateANDs = (fieldId, ands, conditionLookups)=> {
  let result = true
  ands.forEach((and)=> {
    let value = and.left
    if (and.left.match(/^_[0-9]*/)) {
      let field = $(`[name^='kotive-field-'][name*='${and.left}']`)
      const kind = field.prop('type')
      if (kind == 'radio') {
        value = $(`[name^='kotive-field-'][name*='${and.left}']:checked`).val()
      } else if (kind == 'checkbox') {
        value = []
        $(`[name^='kotive-field-'][name*='${and.left}']:checked`).each((i, v)=> {
          const extracted = $(v).attr('id').match(/\[.*\]/)
          value.push(extracted[0].substring(1, extracted[0].length-1))
        })
      } else {
        value = field.val()
      }
    }
    if (result && (value != null)) {
      result = evaluate(value, and.comparison, and.right)
    } else {
      result = false
    }
  })
  return result
}

const evaluate = (left, comparison, right)=> {
  let pass = false
  if (left.indexOf('^') !== -1) left = left.split('^')
  right = right.toString().trim().toLowerCase()

  if ((typeof left === 'object') || (typeof left === 'array')) {
    left.forEach((l)=> {
      if (pass === false) {
        l = l.toString().trim().toLowerCase()
        pass = compare(l, comparison, right)
      }
    })
  } else {
    left = left.toString().trim().toLowerCase()
    pass = compare(left, comparison, right)
  }
  return pass
}

const compare = (left, comparison, right)=> {
  let pass = false
  switch(comparison) {
    case 'is':
      pass = (left == right) ? true : false
      break
    case 'is not':
      pass = (left == right) ? false : true
      break
    case 'greater than':
      pass = (parseFloat(left) > parseFloat(right)) ? true : false
      break
    case 'less than':
      pass = (parseFloat(left) < parseFloat(right)) ? true : false
      break
    case 'contains':
      pass = (left.indexOf(right) !== -1) ? true : false
      break
    default:;
  }
  return pass
}

const setupEvents = (selector, conditionLookups)=> {
  const uniqueIds = []
  $(`${selector} form`).find(`:input[name^='kotive-field-']`).each((i, f)=> {
    let id = $(f).attr('id')
    if (uniqueIds.indexOf(id) === -1) {
      const fieldId = id.substring(id.lastIndexOf('_')+1, id.length)
      $('body').off('change', `[name^='kotive-field-'][name*='_${fieldId}']`)
      $('body').on('change', `[name^='kotive-field-'][name*='_${fieldId}']`, (e)=> {
        evaluateConditions(conditionLookups)
      })
      uniqueIds.push(id)
    }
  })
}
