'use strict'

const appConfig           = require('./../config.es6').getConfig()

export const initAmplitude = ()=> {
  if ((appConfig.amplitudeAppKey != null) && (appConfig.amplitudeAppKey !== '') && (window.location.hostname !== '127.0.0.1') && (window.location.hostname !== 'localhost')) {
    amplitude.getInstance().init(appConfig.amplitudeAppKey, null, {
      batchEvents: true
    })
    return linkUserAmplitude()
  }
}

export const linkUserAmplitude = (userEmail)=> {
  if ((appConfig.amplitudeAppKey != null) && (appConfig.amplitudeAppKey !== '') && (window.location.hostname !== '127.0.0.1') && (window.location.hostname !== 'localhost')) {
    const email = (userEmail != null) ? userEmail : (localStorage.getItem('user-email') != null) ? localStorage.getItem('user-email') : null
    if (email != null) {
      amplitude.getInstance().setUserId(email)
      const userProperties = {
        'hub-version': window.kotive_hub_version
      }
      if (localStorage.getItem('user-id') != null) userProperties['user-id'] = localStorage.getItem('user-id')
      return amplitude.getInstance().setUserProperties(userProperties)
    }
  }
}

export const trackEventAmplitude = (eventName, metaData)=> {
  if ((appConfig.amplitudeAppKey != null) && (appConfig.amplitudeAppKey !== '') && (window.location.hostname !== '127.0.0.1') && (window.location.hostname !== 'localhost')) {
    if (metaData != null) {
      return amplitude.getInstance().logEvent(eventName, metaData)
    } else {
      return amplitude.getInstance().logEvent(eventName)
    }
  }
}

export const trackEvent = (eventName, metaData, analyticsCounter)=> {
  if ((localStorage.getItem('user-email') != null) && (window.location.hostname !== '127.0.0.1') && (window.location.hostname !== 'localhost')) {
    
    if (metaData != null) {
      return trackEventAmplitude(eventName, metaData)
    } else {
      return trackEventAmplitude(eventName)
    }
  }
}

export const logout = ()=> {
}